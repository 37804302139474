/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*/

* {
  box-sizing: border-box;
}

html, body {
  height: calc(100% - 2rem);
  margin: 0;
  background-color: #fafafc;
  font-size: 14px;
}

dialog {
  position: fixed;
  bottom: 40vh;
  margin: auto;
  z-index: 1035;
  border: 0;
  border-radius: 0.375rem;
  border: 1px solid rgba(0,0,0,0.175);
  box-shadow: 0 0 12px rgba(0,0,0,0.175);
  min-width: 50vw;
}

input.form-control {
  font-size: 16px;
  border-width: 0 0 1px 0;
}